import FileSaver from 'file-saver';
import moment from 'moment';
import momentTimezone from 'moment-timezone';
import React from 'react';

import { persistor } from 'redux/store';

import { SelectOption, userType } from 'constants/index';
import {
  accountManagerUserTypes,
  companyStaffUserTypes,
  coUserTypes,
  resellerUserTypes,
  solarCompanyUserTypes,
  solarElectricianUserTypes,
  superAdminUserTypes
} from 'constants/user-types-dropdowns';

import showToast from './notificationToast/notificationToast';

export function getWindowSize() {
  const { innerWidth, innerHeight } = window;
  return { innerWidth, innerHeight };
}

export const clearPersistedStorage = async () => {
  await persistor.purge(); // Clears the persisted storage
};

export const formatDate = (date) => {
  return moment(date).unix() !== -62135612892 && moment(date).unix() !== -2209004892
    ? moment(date, 'DD/MM/YYYY').format('DD/MM/YYYY')
    : '';
};

export const formatDateAlt = (date, showTime = false) => {
  return moment(date).unix() !== -62135612892 && moment(date).unix() !== -2209004892
    ? moment(date).format(showTime ? 'DD/MM/YYYY HH:mm:ss' : 'DD/MM/YYYY')
    : '';
};
export const checkValidDate = (date) => {
  return moment(date).unix() !== -62135612892 && moment(date).unix() !== -2209004892
    ? moment(date, 'DD/MM/YYYY').toDate()
    : '';
};

export const formatDateFormat = (date, format = null) => {
  return moment(date).format(format ? format : 'YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export const checkDocFormValidDate = (date) => {
  return moment(date).unix() !== -62135612892 &&
    moment(date).unix() !== -2209004892 &&
    date !== '0001-01-01T00:00:00' &&
    date
    ? new Date(date)
    : '';
};

export const getFormattedDateFromString = (date) => {
  return date ? moment(date, 'MMM DD YYYY h:mma').format('DD/MM/YYYY') : 'NA';
};

export const formatDateToISOStringUtc = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  // Ensure two digits
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  // Month is zero-based
  const year = date.getFullYear();
  const formattedDate = `${day}/${month}/${year}`;

  const isoString = moment.utc(formattedDate, 'DD/MM/YYYY');
  return isoString;
};

export const formatDateForApi = (date, format = null) => {
  return moment(date).format(format ? format : 'YYYY-MM-DDTHH:mm:ss.SSSZ');
};

export const convertUTCtoOffset = (utcDate, dateTime = false) => {
  const localTimezone = momentTimezone.tz.guess();
  return utcDate
    ? moment(utcDate).unix() !== -62135612892 && moment(utcDate).unix() !== -2209004892
      ? momentTimezone
          .utc(utcDate)
          .tz(localTimezone)
          .format(dateTime ? 'DD/MM/YYYY, h:mm A' : 'DD/MM/YYYY')
      : ''
    : 'N/A';
};

export const findDropdownData = (data, id) => {
  if (!data) return null;
  const foundData = data.find((item) => item.id == id);
  return foundData ? foundData : '';
};

export const findDropdownDataWithSelect = (data, id) => {
  if (!data) return null;
  const foundData = data.find((item) => item.id === id);
  return foundData ? foundData : SelectOption;
};

// export const getCurrentUserStatus = (statusName = "", userTypeID = 0) => {
//   if (
//     userTypeID === userType?.SolarCompany ||
//     userTypeID === userType?.SolarElectrician
//   )
//     return statusName;
//   else return "Registered";
// };

export const arrayToHtml = (arr) => {
  const htmlContent = arr.map((str, index) => (
    <React.Fragment key={index}>
      {str}
      <br />
    </React.Fragment>
  ));

  return <div>{htmlContent}</div>;
};

export const handleApiCall = async (
  setLoading,
  setError,
  apiFunction,
  handleSuccess,
  showSuccessMessage,
  successMessage = false,
  errorMessage
) => {
  setLoading(true);
  setError(null);
  try {
    const resp = await apiFunction();
    if (resp.isSucceeded) {
      handleSuccess(resp.data);
      if (showSuccessMessage) {
        showToast(successMessage, 'success');
      }
    } else {
      setError(resp.message || errorMessage);
      if (resp?.errors?.length > 0) {
        const message = resp?.errors.map((error) => error.errorMessage.replace(/[\[\]"]/g, ''));
        showToast(arrayToHtml(message), 'error');
      } else {
        showToast(resp?.message || errorMessage, 'error');
      }
    }
  } catch (error) {
    setError(error?.message || errorMessage);
    showToast(error?.message || errorMessage, 'error');
  } finally {
    setLoading(false);
  }
};

export const handleFileApiCall = async (
  setLoading,
  setError,
  apiFunction,
  handleSuccess,
  showSuccessMessage,
  successMessage = false,
  errorMessage
) => {
  setLoading(true);
  try {
    const resp = await apiFunction();
    if (resp) {
      await FileSaver.saveAs(resp);
      handleSuccess(resp);
      if (showSuccessMessage) {
        showToast(successMessage, 'success');
      }
    } else {
      setError(resp.message || errorMessage);
      if (resp?.errors?.length > 0) {
        const message = resp?.errors.map((error) => error.errorMessage.replace(/[\[\]"]/g, ''));
        showToast(arrayToHtml(message), 'error');
      } else {
        showToast(errorMessage, 'error');
      }
    }
  } catch (error) {
    setError(error.message || errorMessage);
    showToast(errorMessage, 'error');
    console.log('errorr', error);
  } finally {
    setLoading(false);
  }
};

export const handleKeyDown = (e, handleSubmit) => {
  if (e.key === 'Enter') {
    e.preventDefault();
    handleSubmit();
  }
};

export const getSelectedDateToISOString = (date) => {
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();

  // Create a new Date object with the extracted components at midnight UTC
  let normalizedDate = new Date(Date.UTC(year, month, day));

  // Convert to ISO string
  let isoString = normalizedDate.toISOString();
  return isoString;
};

// get Access type dropdown values for create user and all users table specific to the loggedin Access type
export const getUserTypeListForCreateUser = (userTypeId, allUsers = false) => {
  if (userTypeId) {
    switch (userTypeId) {
      case userType.SuperAdmin:
        return superAdminUserTypes;
      case userType.ComplianceOfficer:
        return allUsers ? [...coUserTypes] : coUserTypes;
      case userType.Reseller:
        return allUsers ? [...resellerUserTypes] : resellerUserTypes;
      case userType.AccountManager:
        return allUsers ? [...accountManagerUserTypes] : accountManagerUserTypes;
      case userType.SolarCompany:
        return allUsers ? [...solarCompanyUserTypes] : solarCompanyUserTypes;
      case userType.CompanyStaff:
        return companyStaffUserTypes;
      case userType.SolarElectrician:
        return solarElectricianUserTypes;
    }
  }
};

export const filterUserStatusDropdown = (data = [], statusId = 0) => {
  if (data?.length > 0) {
    return data.filter((item) => item?.id !== statusId);
  } else {
    return [];
  }
};

export const filterUserStatusDropdownForCompliance = (data = [], statusId = 0) => {
  if (data?.length > 0) {
    let filtered = data.filter((item) => item?.id !== statusId);
    return filtered.map((item) => {
      return {
        id: item.id,
        name: item.text
      };
    });
  } else {
    return [];
  }
};

export const getStreetTypeByStreetName = (streetTypes = [], streetName = '') => {
  if (streetTypes?.length > 0) {
    return streetTypes.find((item) =>
      streetName?.toLowerCase().split(' ').includes(item.name.toLowerCase())
    );
  } else {
    return undefined;
  }
};

export const getFilteredStreetNameByStreetType = (
  streetType = { id: 0, name: '' },
  streetName = ''
) => {
  if (streetType?.name) {
    return streetName
      .split(' ')
      .filter((item) => item.toLowerCase() !== streetType.name.toLowerCase())
      .join(' ');
  } else {
    return streetName;
  }
};
