import * as Yup from 'yup';

import { bsbRegex, mobileReg, onlyAlphabetsRegex, passwordRegex } from '../regex';

export const signupSchema = Yup.object().shape({
  password: Yup.string()
    .matches(passwordRegex, {
      message: 'Must contain Uppercase, Lowercase, Digits and Special Characters'
    })
    .required('Password is Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords Must Match')
    .required('Confirm Password is Required'),
  email: Yup.string().email('Invalid Email Address').required('Email is Required')
  // displayName: Yup.string().required("Display Name is Required"),
  // givenName: Yup.string().required("Given name is Required"),
  // surName: Yup.string().required("Surname is Required"),
  // userType: Yup.object().shape({
  //     id: Yup.string().required("Access type is Required"),
  //     name: Yup.string().required("Access type is Required"),

  // }),
});

export const signupElectricianSchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(onlyAlphabetsRegex, 'Only alphabets are allowed')
    .required('First Name is Required'),
  lastName: Yup.string()
    .matches(onlyAlphabetsRegex, 'Only alphabets are allowed')
    .required('Last Name is Required'),
  password: Yup.string()
    .matches(passwordRegex, {
      message:
        'Must contain At least 8 Characters. Include Uppercase, Lowercase, Digits and Special Characters'
    })
    .required('Password is Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords Must Match')
    .required('Confirm Password is Required'),
  email: Yup.string().email('Invalid Email Address').required('Email is Required'),
  mobileNo: Yup.string()
    .matches(mobileReg, {
      message: 'Mobile Number should Start with 04 and Must be 10 Digits'
    })
    .required('Mobile Number is Required'),
  address: Yup.string().required('Address is Required'),
  streetNumber: Yup.string().required('Street Number is Required'),
  streetName: Yup.string().required('Street Name is Required'),
  streetTypeId: Yup.object().required('Street Type is Required'),
  state: Yup.string().required('State is Required'),
  postCode: Yup.string().required('Post Code is Required'),
  town: Yup.string().required('Town is Required'),
  // installerABN: Yup.string().required('Installer ABN is Required'),
  // businessName: Yup.object().required('Business Name is Required'),

  accountType: Yup.object()
    .shape({
      isPhotovoltaic: Yup.boolean().required(),
      isSWH: Yup.boolean().required()
    })
    .test('one-true', 'At least one account type must be selected', function (value) {
      return value.isPhotovoltaic || value.isSWH;
    }),
  asoLicenseNumber: Yup.string().when('accountType.isPhotovoltaic', {
    is: true,
    then: () => Yup.string().required('SAA Accreditation No. is Required')
  }),
  // electricianLicenseNumber: Yup.string().required(
  //   "Licensed Electrician No is Required"
  // ),

  states: Yup.array().of(
    Yup.object().shape({
      stateId: Yup.object()
        .shape({
          id: Yup.number(),
          name: Yup.string().required('State is Required')
        })
        .required('State is Required'),
      licenseNumber: Yup.string().required('License Number is Required')
    })
  ),
  // .atLeastOneStateTrue("At least one state must have a license number"),

  // offGridSystems: Yup.object().when('accountType.isPhotovoltaic', {
  //   is: true,
  //   then: () =>
  //     Yup.object()
  //       .shape({
  //         isInstall: Yup.boolean(),
  //         isDesign: Yup.boolean(),
  //         isBatteryStorage: Yup.boolean()
  //       })
  //       .test(
  //         'at-least-one',
  //         'At least one of System Install, System Design, or System Battery Storage must be True',
  //         function (value) {
  //           const { isInstall, isDesign, isBatteryStorage } = this.parent.offGridSystems;
  //           if (isInstall || isDesign || isBatteryStorage) {
  //             return true;
  //           }
  //           return false;
  //         }
  //       ),
  //   otherwise: () => Yup.mixed().notRequired()
  // }),
  isTermsAccepted: Yup.boolean().oneOf([true], 'Accept Terms and Conditions is Required')
});

export const signupSolarCompanySchema = Yup.object().shape({
  firstName: Yup.string()
    .matches(onlyAlphabetsRegex, 'Only alphabets are allowed')
    .required('First Name is Required'),
  lastName: Yup.string()
    .matches(onlyAlphabetsRegex, 'Only alphabets are allowed')
    .required('Last Name is Required'),
  password: Yup.string()

    .matches(passwordRegex, {
      message:
        'Must contain at least 8 Characters. Include Uppercase, Lowercase, Digits and Special Characters'
    })
    .required('Password is Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords Must Match')
    .required('Confirm Password is Required'),
  email: Yup.string().email('Invalid Email Address').required('Email is Required'),
  mobileNo: Yup.string()
    .matches(mobileReg, {
      message: 'Mobile Number should Start with 04 and  Must be 10 Digits Long'
    })
    .required('Mobile Number is Required'),
  address: Yup.string().required('Address is Required'),
  companyABN: Yup.string().required('Company ABN is Required'),
  companyName: Yup.object().required('Company Name is Required'),

  // GST: Yup.string().matches(GSTReg, {
  //   message: "Must be digits or decimals",
  // }),
  fromDate: Yup.string().nullable(true),
  toDate: Yup.string().nullable(true),
  entityName: Yup.string(),
  BSB: Yup.string()
    .matches(bsbRegex, {
      message: 'BSB must be Exactly Six Digits'
    })
    .required('BSB is Required'),
  accountNumber: Yup.number()
    .required('Account Number is Required')
    .positive('Account Number Must be Positive')
    .typeError('Account Number Must be a Number'),
  accountName: Yup.string().required('Account Name is Required'),
  streetNumber: Yup.string().required('Street Number is Required'),
  streetName: Yup.string().required('Street Name is Required'),
  state: Yup.string().required('State is Required'),
  postCode: Yup.string().required('Post Code is Required'),
  streetType: Yup.object().required('Street Type is Required'),
  town: Yup.string().required('Town is Required'),
  isTermsAccepted: Yup.boolean().oneOf([true], 'Accept Terms and Conditions is Required')
});

export const signinSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email address').required('Email is Required'),
  password: Yup.string().required('Password is Required')
});

export const recoveryTypeEmailSchema = Yup.object().shape({
  email: Yup.string().email('Invalid Email Address').required('Email is Required')
});

export const recoveryTypeMobileSchema = Yup.object().shape({
  mobile: Yup.string()
    .matches(mobileReg, {
      message: 'Mobile Phone Must Start with 04 and 10 Digits Long'
    })
    .required('Mobile Number is Required')
});

export const resetPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(passwordRegex, {
      message:
        'Must Contain At least 8 Characters. Include Uppercase, Lowercase, Digits and Special Characters'
    })
    .required('Password is Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords Must Match')
    .required('Confirm Password is Required')
});

export const createPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .matches(passwordRegex, {
      message:
        'Must Contain At least 8 Characters. Include Uppercase, Lowercase, Digits and Special Characters'
    })
    .required('Password is Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('password')], 'Passwords Must Match')
    .required('Confirm Password is Required'),
  isTermsAccepted: Yup.boolean().oneOf([true], 'Accept Terms and Conditions is Required')
});

export const otpVerifySchema = Yup.object().shape({
  otp: Yup.string()
    .required()
    .matches(/^[0-9]+$/, 'Must be only Digits')
    .min(6, 'Must be Exactly 6 Digits')
    .max(6, 'Must be Exactly 6 Digits')
});

export const updatePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string()
    .matches(passwordRegex, {
      message:
        'Must Contain At least 8 Characters. Include Uppercase, Lowercase, Digits and Special Characters'
    })
    .required('Old Password is Required'),
  newPassword: Yup.string()
    .matches(passwordRegex, {
      message:
        'Must Contain at least 8 characters. Include Uppercase, Lowercase, Digits and Special Characters'
    })
    .required('New Password is Required'),
  confirmPassword: Yup.string()
    .oneOf([Yup.ref('newPassword')], 'Passwords Must Match')
    .required('Confirm Password is Required')
});
