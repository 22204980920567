import { APP_ROUTES } from 'config/app-routes';
import React, { useEffect, useRef } from 'react';
import { PiSignOutBold } from 'react-icons/pi';
import { RiAccountCircleLine } from 'react-icons/ri';
import { RxCross2 } from 'react-icons/rx';
import { useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';

import { ProfileIcon } from 'assets/svg';

import useLogout from 'hooks/use-logout';
import useMenu from 'hooks/use-menu';

import styles from './profile-modal.module.css';

const ProfileModal = ({ showProfileModal, setShowProfileModal, handlePasswordModal }) => {
  const user = useSelector((state) => state?.user?.user);
  const navigate = useNavigate();
  const location = useLocation();

  const logout = useLogout();

  const menuRef = useRef(null);

  const handleClickOutside = () => {
    setShowProfileModal(false);
  };

  useMenu(menuRef, handleClickOutside);

  const handleLogoutClick = async () => {
    logout();
  };

  useEffect(() => {
    setShowProfileModal(false);
  }, [location]);

  return (
    <>
      {showProfileModal && (
        <div ref={menuRef} className={styles.profile}>
          <div className='text-center position-relative mt-2'>
            <span className={styles.email}>{user?.email}</span>
            <span
              role='button'
              onClick={() => setShowProfileModal(false)}
              className={styles.crossIcon}
            >
              <RxCross2 />
            </span>
          </div>

          <div className='d-flex flex-column align-items-center pt-4'>
            {user?.profileImageURL ? (
              <img
                style={{ borderRadius: '50%' }}
                width={75}
                height={75}
                src={user?.profileImageURL}
                alt='profile'
              />
            ) : (
              <ProfileIcon />
            )}
            <p>Hi, {user?.firstName}</p>
            <p>{user?.userType}</p>
          </div>

          <div className='d-flex gap-2'>
            <div
              onClick={() => navigate(APP_ROUTES.private.PROFILE(user?.userTypeID, user?.id))}
              role='button'
              className={`${styles.flexButtons} ${styles.leftButton}`}
            >
              <RiAccountCircleLine size={20} />
              Manage Account
            </div>
            <div
              role='button'
              onClick={() => handleLogoutClick()}
              className={`${styles.flexButtons} ${styles.rightButton}`}
            >
              <PiSignOutBold />
              <p>Logout</p>
            </div>
          </div>

          <div
            role='button'
            onClick={() => handlePasswordModal()}
            className={` ${styles.cloudButon}`}
          >
            <p className='text-center'>Update Password</p>
          </div>

          <div className={styles.policyContainer}>
            <p role='button' onClick={() => window.open(APP_ROUTES.public.PRIVACY_POLICY)}>
              Privacy Policy
            </p>
            <p style={{ paddingBottom: '.6rem' }}>.</p>
            <p
              role='button'
              onClick={() => window.open(APP_ROUTES.public.TERMS_AND_CONDITIONS, '_blank')}
            >
              Terms of Service
            </p>
          </div>
        </div>
      )}
    </>
  );
};

export default ProfileModal;
