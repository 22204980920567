import { useState } from 'react';
import { useSelector } from 'react-redux';

import AuthenticationService from 'services/authentication';

import showToast from 'utils/notificationToast/notificationToast';

const useAuth = () => {
  const token = useSelector((state) => state.auth.token);

  const [loading, setLoading] = useState(false);

  const sendOTP = async (data, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.sendOTP(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to send OTP', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to send OTP', 'error');
    } finally {
      setLoading(false);
    }
  };

  const validateOTP = async (data, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.validateOTP(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to validate OTP', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to validate OTP', 'error');
    } finally {
      setLoading(false);
    }
  };

  const sendSmsOTP = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.sendSmsOTP(body);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to send OTP', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to send OTP', 'error');
    } finally {
      setLoading(false);
    }
  };

  const validateSmsOTP = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.validateSmsOTP(body);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to validate OTP', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to validate OTP', 'error');
    } finally {
      setLoading(false);
    }
  };

  const userLogin = async (data, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.userLogin(data);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast(resp.message || 'Unable to login', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to login', 'error');
    } finally {
      setLoading(false);
    }
  };

  const userRegister = async (data, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.userRegister(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to register', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to register', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getForgetPasswordLink = async (data, handleSuccess) => {
    setLoading(true);

    try {
      const resp = await AuthenticationService.getForgetPasswordLink(data.email);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to get Link', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to get Link', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getForgetPasswordEmailLink = async (data, handleSuccess) => {
    setLoading(true);

    try {
      const resp = await AuthenticationService.getForgetPasswordLink(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to get Link', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to get Link', 'error');
    } finally {
      setLoading(false);
    }
  };

  const getForgetPasswordMobileLink = async (data, handleSuccess) => {
    setLoading(true);

    try {
      const resp = await AuthenticationService.getForgetPasswordLink(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to get Link', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to get Link', 'error');
    } finally {
      setLoading(false);
    }
  };

  const verifyResetPasswordOTP = async (data, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.verifyResetPasswordOTP(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to verify OTP', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to verify OTP', 'error');
    } finally {
      setLoading(false);
    }
  };
  const verifyResetPasswordEmailOTP = async (data, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.verifyResetPasswordOTP(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to verify OTP', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to verify OTP', 'error');
    } finally {
      setLoading(false);
    }
  };
  const verifyResetPasswordMobileOTP = async (data, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.verifyResetPasswordOTP(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to verify OTP', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to verify OTP', 'error');
    } finally {
      setLoading(false);
    }
  };

  const resetPassword = async (data, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.resetPassword(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to reset password', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to reset password', 'error');
    } finally {
      setLoading(false);
    }
  };
  const resetPasswordByEmail = async (data, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.resetPassword(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to reset password', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to reset password', 'error');
    } finally {
      setLoading(false);
    }
  };
  const resetPasswordByMobile = async (data, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.resetPassword(data);
      if (resp.isSucceeded) {
        handleSuccess(resp);
      } else {
        showToast(resp.message || 'Unable to reset password', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to reset password', 'error');
    } finally {
      setLoading(false);
    }
  };

  const registerElectricianUser = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.registerElectricianUser(body);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast(resp.message || 'Unable to signup', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to signup', 'error');
    } finally {
      setLoading(false);
    }
  };

  const createUser = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.createUser(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
        showToast('User created successfully', 'success');
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to create user', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to create user', 'error');
      }
    } finally {
      setLoading(false);
    }
  };
  const registerSolarCompanyUser = async (body, resellerId, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.registerSolarCompanyUser(body, resellerId);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast(resp.message || 'Unable to signup', 'error');
      }
    } catch (error) {
      showToast(error.message || 'Unable to signup', 'error');
    } finally {
      setLoading(false);
    }
  };

  const sendInvite = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.sendInvite(body, token);
      if (resp.isSucceeded) {
        showToast('Invite send successfully', 'success');
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to send invite', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to send invite', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const createPassword = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.createPassword(body, token);
      if (resp.isSucceeded) {
        showToast('Password created Successfully', 'success');
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to create password', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to create password', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const updatePassword = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.updatePassword(body, token);
      if (resp.isSucceeded) {
        showToast('Password Updated Successfully', 'success');
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to update password', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to update password', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const requestUpdateEmail = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.requestUpdateEmail(body, token);
      if (resp.isSucceeded) {
        showToast('Update Email Request Send Successfully', 'success');
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to send request update email', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to send request update email', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const updateEmail = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.updateEmail(body, token);
      if (resp.isSucceeded) {
        showToast('Email Updated Successfully', 'success');
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to update email', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to update email', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const updateEmailWithKey = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.updateEmailWithKey(body);
      if (resp.isSucceeded) {
        showToast('Email Updated Successfully', 'success');
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to update email', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to update email', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const decryptKey = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.decryptKey(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to decrypt key', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to decrypt key', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const getElectricianStatesDropdown = async (handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.getElectricianStatesDropdown();
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to get electrician stated dropdown list', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to get electrician stated dropdown list', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const getConnectedDevicesList = async (userId,handleSuccess = () => {}) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.getConnectedDevicesList(userId,token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast('Unable to fetch linked devices list', 'error');
      }
    } catch (error) {
      showToast('Unable to fetch linked devices list', 'error');
    } finally {
      setLoading(false);
    }
  };

  const logoutDeviceByIdOrAll = async (body, handleSuccess = () => {}) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.logoutDeviceByIdOrAll(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        showToast('Unable to logout device', 'error');
      }
    } catch (error) {
      showToast('Unable to logout device', 'error');
    } finally {
      setLoading(false);
    }
  };

  const requestUpdateMobileNo = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.requestUpdateMobileNo(body, token);
      if (resp.isSucceeded) {
        showToast('Update Mobile Number Request Send Successfully', 'success');
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to send request update Mobile Number', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to send request update Mobile Number', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const updateMobileNo = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.updateMobileNo(body, token);
      if (resp.isSucceeded) {
        showToast('MobileNo Updated Successfully', 'success');
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to update Mobile Number', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to update Mobile Number', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const updateMobileNoWithKey = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.updateMobileNoWithKey(body);
      if (resp.isSucceeded) {
        showToast('Mobile Number Updated Successfully', 'success');
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to update Mobile Number', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to update Mobile Number', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const decryptMobileNoKey = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.decryptMobileNoKey(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to decrypt key', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to decrypt key', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  const sendInstallerInvite = async (body, handleSuccess) => {
    setLoading(true);
    try {
      const resp = await AuthenticationService.sendInstallerInvite(body, token);
      if (resp.isSucceeded) {
        handleSuccess(resp.data);
      } else {
        if (resp.errors && resp.errors.length > 0) {
          showToast(resp.errors[0].errorMessage, 'error');
        } else {
          showToast(resp.message || 'Unable to send invite', 'error');
        }
      }
    } catch (error) {
      if (error.errors && error.errors.length > 0) {
        showToast(error.errors[0].errorMessage, 'error');
      } else {
        showToast(error.message || 'Unable to send invite', 'error');
      }
    } finally {
      setLoading(false);
    }
  };

  return {
    loading,
    sendOTP,
    validateOTP,
    sendSmsOTP,
    validateSmsOTP,
    userLogin,
    userRegister,
    getForgetPasswordLink,
    getForgetPasswordEmailLink,
    getForgetPasswordMobileLink,
    verifyResetPasswordOTP,
    verifyResetPasswordEmailOTP,
    verifyResetPasswordMobileOTP,
    resetPassword,
    resetPasswordByMobile,
    resetPasswordByEmail,
    registerElectricianUser,
    createUser,
    registerSolarCompanyUser,
    sendInvite,
    createPassword,
    updatePassword,
    requestUpdateEmail,
    updateEmail,
    updateEmailWithKey,
    decryptKey,
    getElectricianStatesDropdown,
    getConnectedDevicesList,
    logoutDeviceByIdOrAll,

    requestUpdateMobileNo,
    updateMobileNo,
    updateMobileNoWithKey,
    decryptMobileNoKey,
    sendInstallerInvite
  };
};

export default useAuth;
